import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "D:/work/BC_Supermarket/bc-supermarket-docs-docz/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { TopBannerBuilderComponent } from '@tvlgiao/bc-supermarket-builder-components';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "top-banner"
    }}>{`Top Banner`}</h1>
    <p><img alt="Top Banner" src={require("../img/home1-top-banner.png")} /></p>
    <p>{`Create or edit the top banner in `}<strong parentName="p">{`Marketing`}</strong>{` > `}<strong parentName="p">{`Banners`}</strong>{`. Remember to choose `}<strong parentName="p">{`Location`}</strong>{` = `}<inlineCode parentName="p">{`Top of Page`}</inlineCode>{`.`}</p>
    <h2 {...{
      "id": "colors-customization"
    }}>{`Colors Customization`}</h2>
    <p>{`To customize this banner's colors, go to `}<strong parentName="p">{`Storefront Design`}</strong>{` > `}<strong parentName="p">{`My Themes`}</strong>{`, click `}<strong parentName="p">{`Customize`}</strong>{` button of the current theme to open `}<strong parentName="p">{`Theme Editor`}</strong>{`. `}</p>
    <p><img alt="Click customize theme" src={require("../img/click-customize-theme.png")} /></p>
    <p>{`Find the options below:`}</p>
    <p><img alt="Theme editor top banner" src={require("../img/theme-editor-top-banner.png")} /></p>
    <h2 {...{
      "id": "advanced-top-banner"
    }}>{`Advanced Top Banner`}</h2>
    <p><img alt="Advanced top header banner" src={require("../img/home1-banner-adv-top-header.png")} /></p>
    <p>{`You can show the top banner on all pages or on all other page exclude the homepage. Go to `}<strong parentName="p">{`Theme Editor`}</strong>{` > `}<strong parentName="p">{`Banners`}</strong>{` > choose `}<strong parentName="p">{`Advanced top header banner`}</strong>{`:`}</p>
    <p><img alt="configure Advanced top header banner" src={require("../img/theme-editor-banner-adv-top-header.png")} /></p>
    <p>{`Edit the top banner's content in `}<strong parentName="p">{`Marketing`}</strong>{` > `}<strong parentName="p">{`Banners`}</strong>{`, click button `}<strong parentName="p">{`Create a Banner`}</strong>{`. In `}<strong parentName="p">{`Banner Content`}</strong>{` box, click the square `}<inlineCode parentName="p">{`HTML`}</inlineCode>{` button to open `}<strong parentName="p">{`HTML Source Editor`}</strong>{`.`}</p>
    <p>{`Use the editor below to generate HTML. Click `}<strong parentName="p">{`Export HTML`}</strong>{` button, copy & paste the generated HTML to `}<strong parentName="p">{`HTML Source Editor`}</strong>{`.`}</p>
    {
      /* SupermarketAdvTopHeader */
    }
    <TopBannerBuilderComponent mdxType="TopBannerBuilderComponent" />
    <p>{`Other fields should be configured as below:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Show on Page`}</strong>{`: `}<inlineCode parentName="li">{`Search Results Page`}</inlineCode></li>
      <li parentName="ul"><strong parentName="li">{`Visible`}</strong>{`: Checked`}</li>
      <li parentName="ul"><strong parentName="li">{`Location`}</strong>{`: `}<inlineCode parentName="li">{`Top of Page`}</inlineCode></li>
    </ul>
    <h3 {...{
      "id": "tutorial-video"
    }}>{`Tutorial video`}</h3>
    <p>{`Also check out the tutorial video below:`}</p>
    <ul>
      <li parentName="ul">{`Display a top banner on all pages with the new documentation:`}</li>
    </ul>
    <iframe width="560" height="315" src="https://www.youtube.com/embed/7HW54Gp72Ok" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
    <ul>
      <li parentName="ul">{`Old video:`}</li>
    </ul>
    <iframe width="560" height="315" src="https://www.youtube.com/embed/pa8_08I3Jn0" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      